import { differenceInDays } from 'date-fns'
import { RatingState } from './types'
import { ItemsState } from 'store/items/types'
import { orderIDForItemID } from 'store/items/selectors'
import { OrdersState, OrderStatusTypes, OrderStatus } from 'store/orders/types'
import { currentOrderStatus } from 'store/orders/selectors'

export const npsForItem = (
  ratingsState: RatingState,
  itemsState: ItemsState,
  itemID: number
): number | null => {
  const orderID = orderIDForItemID(itemsState, itemID)

  if (!orderID) {
    return null
  }

  const nps = ratingsState.nps?.[orderID]?.results?.[0]

  if (!nps) {
    return null
  }

  return nps.value
}

export const latestNPSForOrder = (state: RatingState, orderID: number) => {
  if (!state.nps[orderID]) {
    return null
  }

  if (state.nps[orderID].results.length === 1) {
    return state.nps[orderID].results[0]
  }

  return state.nps[orderID].results
    .slice()
    .sort((a, z) => z.created_at.getTime() - a.created_at.getTime())[0]
}

export const starRatingForItem = (
  ratingsState: RatingState,
  itemsState: ItemsState,
  itemID: number
): number | null => {
  const nps = npsForItem(ratingsState, itemsState, itemID)

  if (!nps) {
    return null
  }

  return nps / 2
}

export const canRateOrder = (
  ratingsState: RatingState,
  ordersState: OrdersState,
  orderID: number
): boolean => {
  const npsData = ratingsState.nps[orderID]
  if (!npsData || !npsData.can_submit) {
    return false
  }

  const order = ordersState.orders[orderID]
  if (!order) {
    return false
  }

  const orderStatus: OrderStatus | null = currentOrderStatus(order.status)
  if (
    !orderStatus ||
    orderStatus.status !== OrderStatusTypes.Completed ||
    !orderStatus.completed_at
  ) {
    return false
  }

  const daysSinceCompletion = differenceInDays(
    new Date(),
    new Date(orderStatus.completed_at)
  )

  return daysSinceCompletion <= 7
}

export const isSubmittingNPS = (state: RatingState, orderID: number) =>
  state.meta.SUBMIT_ORDER_NPS[orderID]?.isLoading || false
